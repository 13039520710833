import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';

const createController: CreateControllerFn = async (
  controllerParams: ControllerParams,
) => {
  console.log(controllerParams);
  return {
    async pageReady() {
      // Here you can pass props to the Widget
    },
  };
};

export default createController;
